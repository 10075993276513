<template>

<v-container
  id="alerts"
  fluid
  tag="section"
>
<loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading> 
<v-row justify="center">
     <v-dialog
      v-model="toggleFileUploadModel"
      max-width="600"
    >
      <template>
      <v-card>
        <v-form
            ref="addReportForm"
            v-model="validateAddReport"
            lazy-validation
          >
        <v-card-title>
          <span v-if="!editReportMode" class="headline">Add Report</span>
          <span v-else class="headline">Edit Report</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
                  <v-menu

                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date*"
                    readonly
                    outlined
                    dense
                    v-model="reportDate"
                    :value="fromDateDisp"
                    :rules="dateRules"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  v-model="reportDate"
                  no-title
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-row>
            <v-row>
              <v-text-field
                v-model="reportName"
                :counter="100"
                label="Name*"
                outlined
                dense
                required
                :rules="nameRules"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-textarea
                name="input-7-1"
                required
                outlined
                dense
                v-model="reportDesc"
                :rules="descRules"
                label="Description*"
              ></v-textarea>
            </v-row>
            <v-row v-if="editReportMode">
              <span>Current File: {{editFileName}}</span>

            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-file-input
                  label="File input*"
                  outlined
                  dense
                  v-model="reportFile"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validateAddReport"
            color="primary"
            class="mr-4"
            @click="addReport"
            v-if="!editReportMode"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="!validateAddReport"
            color="primary"
            class="mr-4"
            @click="updateReport"
            v-else
          >
            Update
          </v-btn>
          <v-btn
            color="default"
            @click="toggleFileUploadModel = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
      </template>
    </v-dialog>
    </v-row>
  <div class="text-right">
      <v-btn
        small
        color="primary"

        dark
        class="mr-4"
        @click="openReportModel(false)">
        Add Report
      </v-btn>
  </div>
  <v-row>
    <v-col cols="12">
     <v-card>
  <div>
    <v-data-table
      :headers="headers"
      item-key="product_id"
      :page.sync="page"
      :items="reports_data"
      :options.sync="options"
      :server-items-length="totalReports"
      :loading="loading"
      class="elevation-1"
      hide-default-footer
      @page-count="pageCount = $event"

    >
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        v-show="item.fid_path"
        class="mr-2"
        title ="Download Attachment"
        @click="downloadFile(item.fid)"
      >
        mdi-cloud-download
      </v-icon>
      <v-icon
        small
        class="mr-2"
        title ="Edit Report"
        @click="openReportModel(true, item.fid)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        title ="Delete Report"
        @click="deleteFile(item.fid)"
      >
        mdi-delete
      </v-icon>
    </template>
    </v-data-table>
      <!-- <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="10"
      ></v-pagination> -->

      <div>

      </div>
  </div>
  <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>



<script>
import axios from 'axios'
import DashboardCoreView from '@/views/dashboard/CompanyAutocomplete'
import StatesCoreView from '@/views/dashboard/StateAutocomplete'
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import moment from 'moment'

  export default {
    name: 'ManageReports',

    components:{
      DashboardCoreView,
      StatesCoreView,
      Loading,
      Pagination
    },


    data: () => ({
      //for loading//
      loading:false,
      isLoading: true,
      fullPage: true,
      menu3: false,
      ///
      query: "",
      toggleFileUploadModel:false,
      //form
      validateAddReport: false,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      dateRules: [
        v => !!v || 'Date is required',
      ],
      descRules: [
        v => !!v || 'Description is required',
      ],
      reportDate:null,
      reportName:'',
      reportDesc:'',
      reportFile:null,
      editFid:'',
      editFileName:'',
      //
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      showPerPage: false,
      ///
      //////table data starts here////
      selected: [],
      options: {},
      sort:'',
      page:1,
      reports_data: [],
      pageCount:0,
      totalReports: 0,
      // pagination: {
      //   rowsPerPage: 100,
      //   descending: false,
      //    sortable: false,
      //   page: 1
      // },
      editReportMode:false,
      fromDateMenu: false,
     fromDateVal: null,
    //  minDate: "2020-01-05",
    //  maxDate: "2019-08-30",
      headers: [
          { text: 'Date', value: 'fid_createddate',width:"150px",sortable: false  },
          { text: 'Name', value: 'fid_name',sortable: false   },
          { text: 'Description', value: 'fid_desc',sortable: false   },
          { text: 'File', value: 'fid_filename',sortable: false  },
          { text: 'File Size', value: 'fid_content_length' ,sortable: false },
          {text: 'Action', value: 'actions',width:"150px" ,sortable: false }

        ],
        ////// table data ends here
    }),

      computed: {
        fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },

  },
  watch:{
      '$store.state.Rawdata.current_active_module': function(o) {
        if(o){
          this.getTempData(this.page)
        }
      },
      page(obj){
      this.reports_data=[]
      this.page = obj
      //this.getTempData(obj)
    }
  },
  mounted () {
    //this.getTempData(this.pagination.page)
  },

  methods: {
    //pagination methods
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getTempData(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getTempData(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getTempData(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getTempData(this.page);
    },
    /////
    getTempData(page = "", query = ""){
        let view = this
        view.isLoading = true;
        let manage_reports_url = this.$url('MANAGEREPORTS')

      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
      .get(manage_reports_url + "?q=" + view.query + "&page=" + view.page)
      .then(response => {
        view.isLoading = false;
        console.log(response.data.payload);
        view.reports_data = response.data.payload.reports_data
        view.totalReports = response.data.payload.count
        view.totRecords = response.data.payload.count
        view.pageCount = parseInt(response.data.payload.total_pages)

      })
      .catch((error) => {
           console.error(error);
           view.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      },
    openReportModel(editMode,fid = 0 ){
        this.toggleFileUploadModel = true
        if(editMode){
          this.editReportMode = true
        }else{
          this.editReportMode = false
          this.reportName = ''
          this.reportDesc = ''
          this.reportDate = null
        }
        if(fid != 0){
          this.getClientReport(fid);
        }
    },
    addReport(){
      let view = this
      if(view.$refs.addReportForm.validate()){
        console.log('ssds');
          let formData = new FormData();
      formData.append('name', this.reportName);
      formData.append('date', this.reportDate);
      formData.append('desc', this.reportDesc);
      formData.append('file', this.reportFile);
      formData.append('fileSize', this.reportFile ? this.reportFile.size : null);
      let manage_reports_url = this.$url('MANAGEREPORTS')
      view.isLoading = true
      this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
      .post(manage_reports_url, formData, {
        headers: {
                  // 'Authorization': accestoken,
                  'Content-Type': 'multipart/form-data'
                  }
      })
      .then(response => {
        view.isLoading = false;
        view.toggleFileUploadModel = false
        view.$swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: response.data.message,
                    })
        view.getTempData(view.page)
      })
      .catch((error) => {
           console.error(error);
           view.isLoading = false
           view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }

    },
    updateReport(){
      let view = this
      if(view.$refs.addReportForm.validate()){
        console.log('ssds');
          let formData = new FormData();
          view.editFid
      formData.append('report_id', this.editFid);
      formData.append('name', this.reportName);
      formData.append('date', this.reportDate);
      formData.append('desc', this.reportDesc);
      formData.append('file', this.reportFile);
      formData.append('fileSize', this.reportFile ? this.reportFile.size : null);
      let manage_reports_url = this.$url('MANAGEREPORTS')
      view.isLoading = true
      this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: view })
      .put(manage_reports_url, formData, {
        headers: {
                  // 'Authorization': accestoken,
                  'Content-Type': 'multipart/form-data'
                  }
      })
      .then(response => {
        view.isLoading = false;
        view.toggleFileUploadModel = false
        view.$swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: response.data.message,
                    })
        view.getTempData(view.page)
      }).catch((error) => {
           console.error(error);
           view.isLoading = false
           view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }

    },
    getClientReport(fid){
      let view = this
      view.isLoading = true;
        let get_report_url = this.$url('GETCLIENTREPORT')

        this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
      .get(get_report_url+'?fid='+fid)
      .then(response => {
        view.isLoading = false;
        console.log(response.data.payload);
        console.log(moment(response.data.payload.data.fid_sortdate).format('YYYY-MM-DD'));
        view.editFid = response.data.payload.data.fid
        view.reportName = response.data.payload.data.fid_name
        view.reportDesc = response.data.payload.data.fid_desc
        view.reportDate = moment(response.data.payload.data.fid_sortdate).isValid() ? moment(response.data.payload.data.fid_sortdate).format('YYYY-MM-DD') : response.data.payload.data.fid_sortdate;
        view.editFileName = response.data.payload.data.fid_filename
      })

    },
    downloadFile(fileID){
        let view = this
        view.isLoading = true;
        let download_file_uploads_url = this.$url('DOWNLOADCLIENTREPORTFILE')
        this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).get(download_file_uploads_url+'?fileID='+fileID)
      .then(response => {
        view.isLoading = false;
        console.log(response.data.payload.url);
        if(response.data.statusCode === 200){
          let file_url = response.data.payload.url
          window.open(file_url)
        }
      })
    },
    deleteFile(fileID){
        let view = this
        let file_uploads_url = this.$url('MANAGEREPORTS')
        view.$swal
            .fire({
              title: "Are you sure ?",
              text:
                "This will delete this file",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes, delete it !",
              cancelButtonText: "No, cancel !",
              closeOnConfirm: false,
              closeOnCancel: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                view.isLoading = true;
                    this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: view })
                    .delete(file_uploads_url+'?fileID='+fileID)
                    .then(response => {
                      view.isLoading = false;
                      if(response.data.statusCode === 200){
                        console.log(response.data.payload.message);
                        view.$swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.payload.message,
                                  })
                        view.getTempData(view.page)
                      }
                    })
              } else if (result.isDenied) {
                view.$swal.fire(
                  "Cancelled",
                  "File was not deleted!",
                  "error"
                );
              }
            });

    },
  },
  }
</script>
